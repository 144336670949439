import { MvnCodeFE } from "../const";

export const BUILDER_STUDY_BUILDER_USER_LANG: {
  BUILDER_STUDY_BUILDER_USER_PAGE_TITLE_DESCRIPTION: MvnCodeFE;
  BUILDER_STUDY_BUILDER_USER_ADD_BTN: MvnCodeFE;
  BUILDER_STUDY_BUILDER_USER_ADD_SUCC: MvnCodeFE;
  BUILDER_STUDY_BUILDER_USER_ADD_FAIL: MvnCodeFE;
  BUILDER_STUDY_BUILDER_USER_MODIFY_SUCC: MvnCodeFE;
  BUILDER_STUDY_BUILDER_USER_MODIFY_FAIL: MvnCodeFE;
  BUILDER_STUDY_BUILDER_USER_REMOVE_SUCC: MvnCodeFE;
  BUILDER_STUDY_BUILDER_USER_REMOVE_FAIL: MvnCodeFE;
  BUILDER_STUDY_BUILDER_USER_RESTORE_SUCC: MvnCodeFE;
  BUILDER_STUDY_BUILDER_USER_RESTORE_FAIL: MvnCodeFE;
  BUILDER_STUDY_BUILDER_USER_REMOVE_DIALOG_HEADER: MvnCodeFE;
  BUILDER_STUDY_BUILDER_USER_REMOVE_DIALOG_TITLE: MvnCodeFE;
} = {
  BUILDER_STUDY_BUILDER_USER_PAGE_TITLE_DESCRIPTION: {
    ko: "Builder 사용자를 추가하고 Role과 권한을 관리할 수 있습니다.",
    en: "Add Builder users and manage their roles and privileges.",
  },
  BUILDER_STUDY_BUILDER_USER_ADD_BTN: {
    ko: "Add User",
    en: "Add User",
  },
  BUILDER_STUDY_BUILDER_USER_ADD_SUCC: {
    ko: "Builder User를 성공적으로 추가하였습니다.",
    en: "You have created Builder User successfully.",
  },
  BUILDER_STUDY_BUILDER_USER_ADD_FAIL: {
    ko: "Builder User 추가에 실패했습니다. ({message})",
    en: "Failed to create Builder User. ({message})",
  },
  BUILDER_STUDY_BUILDER_USER_MODIFY_SUCC: {
    ko: "Builder User를 성공적으로 수정하였습니다.",
    en: "You have modified Builder User successfully.",
  },
  BUILDER_STUDY_BUILDER_USER_MODIFY_FAIL: {
    ko: "Builder User 수정에 실패했습니다. ({message})",
    en: "Failed to modify Builder User. ({message})",
  },
  BUILDER_STUDY_BUILDER_USER_REMOVE_SUCC: {
    ko: "Builder User를 성공적으로 삭제하였습니다.",
    en: "You have removed Builder User successfully.",
  },
  BUILDER_STUDY_BUILDER_USER_REMOVE_FAIL: {
    ko: "Builder User 삭제에 실패했습니다. ({message})",
    en: "Failed to remove Builder User. ({message})",
  },
  BUILDER_STUDY_BUILDER_USER_RESTORE_SUCC: {
    ko: "Builder User를 성공적으로 복구하였습니다.",
    en: "You have restored Builder User successfully.",
  },
  BUILDER_STUDY_BUILDER_USER_RESTORE_FAIL: {
    ko: "Builder User 복구에 실패했습니다. ({message})",
    en: "Failed to restore Builder User. ({message})",
  },
  BUILDER_STUDY_BUILDER_USER_REMOVE_DIALOG_HEADER: {
    ko: "Remove user",
    en: "Remove user",
  },
  BUILDER_STUDY_BUILDER_USER_REMOVE_DIALOG_TITLE: {
    ko: "해당 사용자를 삭제하기 위해 이메일과 비밀번호를 입력하세요.",
    en: "Please enter your e-mail and password to remove this user from the study.",
  },
};
