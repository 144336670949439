import { classNames } from "@maven-helper/functions/Misc.function";
import React, { lazy, Suspense, useEffect } from "react";
import { datadogRum } from "@datadog/browser-rum";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { useStore } from "react-redux";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import { setDefaultTransformResponse } from "@coralblack/flax";
import { loadTheme } from "@fluentui/react";
import { SSO } from "@pages/auth/layout/SSO";
import { ErrorLayout } from "@pages/system/layout/Error";
import { MyInfoGuard } from "./MyInfoGuard";
import pkg from "../package.json";
import { dialogIfTokenDestroyedByNewToken } from "@maven-surface/components/common/auth/TokenInvalidDialog";
import { MsaAuthRoute } from "@maven-surface/components/common/MsaAuthRoute";
import { RootState } from "@maven-rest/states";
import "@maven-surface/assets/style/App.scss";
import "@coralblack/flax/dist/styles/index.css";
import "@assets/style/variables.app.scss";

const getAppBadge = () => {
  switch (process.env.REACT_APP_STAGE) {
    case "local": {
      return "LOCAL";
    }
    case "develop": {
      return "DEV";
    }
    case "staging": {
      return "STG";
    }
    case "demo": {
      return "DEMO";
    }
    case "production": {
      return "REAL";
    }
    default: {
      return "";
    }
  }
};

loadTheme({
  defaultFontStyle: { fontFamily: '"Nunito", "Noto Sans KR", sans-serif', fontWeight: "400" },
  fonts: {
    xSmall: {
      fontSize: "0.69rem",
    },
    small: {
      fontSize: "0.75rem",
    },
    medium: {
      fontSize: "0.82rem",
    },
    large: {
      fontSize: "0.91rem",
    },
    xLarge: {
      fontSize: "1rem",
    },
  },
});

if (process.env.REACT_APP_STAGE !== "local") {
  datadogRum.init({
    applicationId: "cf49f779-0727-4f10-bb4d-68ec4cb0107a",
    clientToken: "pub18b9e3369d019bf4045474df66ea1f4c",
    site: "datadoghq.com",
    service: "com.mavenclinical.builder",
    env: process.env.REACT_APP_STAGE?.toLowerCase() || "UNKNOWN",
    version: pkg.version,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 20,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: "mask-user-input",
  });

  datadogRum.startSessionReplayRecording();
}

const HomeLayout = lazy(() => import("@layouts/home/Index"));
const StudyLayout = lazy(() => import("@layouts/study/Index"));
const StudyPayloadLayout = lazy(() => import("@layouts/studyPayload/Index"));
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: () => false,
    },
  },
});

function App() {
  const session = useStore<RootState>().getState().session;
  const appBadge = getAppBadge();
  const appBadgeDisabled = process.env.REACT_APP_STAGE === "production";

  useEffect(() => {
    document.body.classList.add("ready");

    setTimeout(() => {
      document.getElementById("spinner")?.remove();
    }, 1500);
  });

  setDefaultTransformResponse([
    function (data) {
      const o = JSON.parse(data);

      dialogIfTokenDestroyedByNewToken(o);

      return o;
    },
  ]);

  return (
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <div className="app-wrapper">
          {!appBadgeDisabled && appBadge && (
            <div className={classNames("notranslate app-sidebar-ribbon", `--${String(appBadge).toLowerCase()}`)}>{appBadge}</div>
          )}
          <Suspense
            fallback={
              <div className="suspense-loading">
                <div className="loading">
                  <div className="loader" />
                </div>
              </div>
            }
          >
            <Switch>
              <Route exact={true} path="/404" render={ErrorLayout} />
              <Route exact={true} path="/auth" render={() => <SSO session={session} />} />
              <MsaAuthRoute
                authStrategy={"AUTHORIZED_ONLY"}
                session={session}
                path="/s/:studyId/payloads/:payloadId"
                render={() => <MyInfoGuard component={StudyPayloadLayout} />}
              />
              <MsaAuthRoute
                authStrategy={"AUTHORIZED_ONLY"}
                session={session}
                path="/s/:studyId"
                render={() => <MyInfoGuard component={StudyLayout} />}
              />
              <MsaAuthRoute
                authStrategy={"AUTHORIZED_ONLY"}
                session={session}
                path="/"
                render={() => <MyInfoGuard component={HomeLayout} />}
              />
              <Redirect from="*" to="/404?r=rt" />
            </Switch>
          </Suspense>
        </div>
      </BrowserRouter>
      <ReactQueryDevtools />
    </QueryClientProvider>
  );
}

export default App;
