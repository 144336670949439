import { MvnCodeInitiate } from "../../lib/const";
import { MvnCodeFE } from "../const";

export const CDMS_STUDY_SAFETY_INTEGRATION_LANG: {
  CDMS_STUDY_SAFETY_INTG_PAGE_TITLE: MvnCodeFE;
  CDMS_STUDY_SAFETY_INTG_PAGE_TITLE_DESCRIPTION: MvnCodeFE;
  CDMS_STUDY_SAFETY_INTG_REP_PV: MvnCodeFE;
  CDMS_STUDY_SAFETY_INTG_MY_INVITATION_DESC: MvnCodeFE;
  CDMS_STUDY_SAFETY_INTG_USER_INVITATION_SEND_INVITATION_MAIL: MvnCodeFE;
  CDMS_STUDY_SAFETY_INTG_USER_INVITATION_APPROVE_STATUS: MvnCodeFE;
  CDMS_STUDY_SAFETY_INTG_USER_INVITATION_APPROVE_DATE: MvnCodeFE;
  CDMS_STUDY_SAFETY_INTG_USER_INVITATION_LAST_MAIL_SENT: MvnCodeFE;
  CDMS_STUDY_SAFETY_INTG_USER_APPROVE_STATUS_REQUESTED: MvnCodeFE;
  CDMS_STUDY_SAFETY_INTG_USER_APPROVE_STATUS_REJECTED: MvnCodeFE;
  CDMS_STUDY_SAFETY_INTG_USER_APPROVE_STATUS_REQUEST_EXPIRED: MvnCodeFE;
  CDMS_STUDY_SAFETY_INTG_USER_APPROVE_STATUS_APPROVED: MvnCodeFE;
  CDMS_STUDY_SAFETY_INTG_USER_SELECTED_ORGANIZATION: MvnCodeFE;
  CDMS_STUDY_SAFETY_INTG_USER_SELECTED_ORGANIZATION_TOOLTIP_DESC: MvnCodeFE;
  CDMS_STUDY_SAFETY_INTG_PV_USER_LIST_SYSTEM_ACCESS_PRIV_TOOLTIP_DESC: MvnCodeFE;
  CDMS_STUDY_SAFETY_INTG_USER_PV_USER_LIST_REP_PV_TOOLTIP_DESC: MvnCodeFE;
  CDMS_STUDY_SAFETY_INTG_SELECT_ORGANIZATION_DIALOG_HEADER: MvnCodeFE;
  CDMS_STUDY_SAFETY_INTG_SELECT_SAFETY_ORGANIZATION: MvnCodeFE;
  CDMS_STUDY_SAFETY_INTG_SELECT_SAFETY_ORGANIZATION_DESC: MvnCodeFE;
  CDMS_STUDY_SAFETY_INTG_USER_INVITATION_APPROVED_SUCC: MvnCodeFE;
  CDMS_STUDY_SAFETY_INTG_USER_INVITATION_APPROVED_FAIL: MvnCodeFE;
  CDMS_STUDY_SAFETY_INTG_USER_INVITATION_REJECT_CONFIRM_DESC: MvnCodeFE;
  CDMS_STUDY_SAFETY_INTG_USER_INVITATION_REJECTED_SUCC: MvnCodeFE;
  CDMS_STUDY_SAFETY_INTG_USER_INVITATION_REJECTED_FAIL: MvnCodeFE;
  CDMS_STUDY_SAFETY_INTG_USER_INVITATION_MAIL_SEND_DIALOG_EXIST_APPROVED_USER_DESC: MvnCodeFE;
  CDMS_STUDY_SAFETY_INTG_USER_INVITATION_MAIL_SEND_DIALOG_EXIST_REQUESTED_USER_DESC: MvnCodeFE;
  CDMS_STUDY_SAFETY_INTG_USER_INVITATION_MAIL_SEND_SUCC: MvnCodeFE;
  CDMS_STUDY_SAFETY_INTG_USER_INVITATION_MAIL_SEND_FAIL: MvnCodeFE;
  CDMS_STUDY_SAFETY_INTG_USER_VIEW_TITLE: MvnCodeFE;
  CDMS_STUDY_SAFETY_INTG_USER_VIEW_DESC: MvnCodeFE;
  CDMS_STUDY_SAFETY_INTG_USER_ORGANIZATION_MODIFY_SUCC: MvnCodeFE;
  CDMS_STUDY_SAFETY_INTG_USER_ORGANIZATION_MODIFY_FAIL: MvnCodeFE;
  CDMS_STUDY_SAFETY_INTG_REQUEST_SUCC: MvnCodeFE;
  CDMS_STUDY_SAFETY_INTG_REQUEST_FAIL: MvnCodeFE;
} = {
  CDMS_STUDY_SAFETY_INTG_PAGE_TITLE: {
    ko: "Safety Integration",
    en: "Safety Integration",
  },
  CDMS_STUDY_SAFETY_INTG_PAGE_TITLE_DESCRIPTION: {
    ko: "Safety로 연동된 이상 반응 데이터 내역을 확인할 수 있습니다.",
    en: "Check the list of SAEs integrated into Safety.",
  },
  CDMS_STUDY_SAFETY_INTG_REP_PV: {
    ko: "대표 PV",
    en: "Rep PV",
  },
  CDMS_STUDY_SAFETY_INTG_MY_INVITATION_DESC: {
    ko: "해당 Study의 대표 PV로 승인 요청받았습니다. 연동 받을 Organization을 선택해야 승인 절차가 완료됩니다.",
    en: "You have been requested to be the rep PV of this study. Please select the Organization for integration to approve the request.",
  },
  CDMS_STUDY_SAFETY_INTG_USER_INVITATION_SEND_INVITATION_MAIL: {
    ko: "메일 전송",
    en: "Send Mail",
  },
  CDMS_STUDY_SAFETY_INTG_USER_INVITATION_APPROVE_STATUS: {
    ko: "승인 여부",
    en: "Approval Status",
  },
  CDMS_STUDY_SAFETY_INTG_USER_INVITATION_APPROVE_DATE: {
    ko: "승인일",
    en: "Approved Date",
  },
  CDMS_STUDY_SAFETY_INTG_USER_INVITATION_LAST_MAIL_SENT: {
    ko: "최근 메일 전송일",
    en: "Last sent Date",
  },
  CDMS_STUDY_SAFETY_INTG_USER_APPROVE_STATUS_REQUESTED: {
    ko: "승인 대기",
    en: "Pending",
  },
  CDMS_STUDY_SAFETY_INTG_USER_APPROVE_STATUS_REJECTED: {
    ko: "승인 거절",
    en: "Rejected",
  },
  CDMS_STUDY_SAFETY_INTG_USER_APPROVE_STATUS_REQUEST_EXPIRED: {
    ko: "요청 만료",
    en: "Expired",
  },
  CDMS_STUDY_SAFETY_INTG_USER_APPROVE_STATUS_APPROVED: {
    ko: "승인",
    en: "Approved",
  },
  CDMS_STUDY_SAFETY_INTG_USER_SELECTED_ORGANIZATION: {
    ko: "Selected Organization",
    en: "Selected Organization",
  },
  CDMS_STUDY_SAFETY_INTG_USER_SELECTED_ORGANIZATION_TOOLTIP_DESC: {
    ko: "대표 PV는 이상 반응 데이터를 연동할 Safety Organization을 선택합니다.\n선택된 Safety Organization으로 이상 반응 데이터가 연동됩니다.",
    en: "The rep PV selects the Safety Organization to integrate the SAE data.\nSAE data will be integrated into the selected Safety Organization.",
  },
  CDMS_STUDY_SAFETY_INTG_PV_USER_LIST_SYSTEM_ACCESS_PRIV_TOOLTIP_DESC: {
    ko: "System 접근 권한이 없는 계정은 Maven Clinical Cloud에 로그인 할 수 없습니다.",
    en: "Accounts without system access privilege cannot log in to Maven Clinical Cloud.",
  },
  CDMS_STUDY_SAFETY_INTG_USER_PV_USER_LIST_REP_PV_TOOLTIP_DESC: {
    ko: "대표 PV는 PV User 상세페이지에서 지정할 수 있습니다.",
    en: "The rep PV can be designated in the PV User details page.",
  },
  CDMS_STUDY_SAFETY_INTG_SELECT_ORGANIZATION_DIALOG_HEADER: {
    ko: "Select Organization",
    en: "Select Organization",
  },
  CDMS_STUDY_SAFETY_INTG_SELECT_SAFETY_ORGANIZATION: {
    ko: "Safety Organization",
    en: "Safety Organization",
  },
  CDMS_STUDY_SAFETY_INTG_SELECT_SAFETY_ORGANIZATION_DESC: {
    ko: "CDMS 이상 반응 데이터를 연동 받을 Safety Organization을 선택해주세요.",
    en: "Please select a Safety Organization to integrate the CDMS SAE data.",
  },
  CDMS_STUDY_SAFETY_INTG_USER_INVITATION_APPROVED_SUCC: {
    ko: "대표 PV 승인 및 Organization 선택이 완료되었습니다.",
    en: "Rep PV approval and organization selection has been completed.",
  },
  CDMS_STUDY_SAFETY_INTG_USER_INVITATION_APPROVED_FAIL: {
    ko: "대표 PV 승인 및 Organization 선택에 실패하였습니다. ({message})",
    en: "Rep PV approval and organization selection has failed. ({message})",
  },
  CDMS_STUDY_SAFETY_INTG_USER_INVITATION_REJECT_CONFIRM_DESC: {
    ko: "대표 PV 지정을 거절하시겠습니까?",
    en: "Reject the Rep PV designation?",
  },
  CDMS_STUDY_SAFETY_INTG_USER_INVITATION_REJECTED_SUCC: {
    ko: "대표 PV 지정이 거절되었습니다.",
    en: "You have rejected the Rep PV designation.",
  },
  CDMS_STUDY_SAFETY_INTG_USER_INVITATION_REJECTED_FAIL: {
    ko: "대표 PV 지정 거절에 실패하였습니다. ({message})",
    en: "You have failed to reject the Rep PV designation. ({message})",
  },
  CDMS_STUDY_SAFETY_INTG_USER_INVITATION_MAIL_SEND_DIALOG_EXIST_APPROVED_USER_DESC: {
    ko: "이미 대표 PV가 지정되어있습니다.\n메일을 전송하여 대표 PV를 변경하시겠습니까?",
    en: "A rep PV has already been designated.\nWould you like to send the email to change the rep PV?",
  },
  CDMS_STUDY_SAFETY_INTG_USER_INVITATION_MAIL_SEND_DIALOG_EXIST_REQUESTED_USER_DESC: {
    ko: "이미 다른 계정에게 대표 PV 지정 요청 메일을 전송한 상태입니다.\n메일을 전송하시겠습니까? (이전에 보낸 메일은 만료됩니다.)",
    en: "You have sent a rep PV designation approval request to another account.\nWould you like to send the email? (The previously sent email will expire.)",
  },
  CDMS_STUDY_SAFETY_INTG_USER_INVITATION_MAIL_SEND_SUCC: {
    ko: "메일 전송에 성공하였습니다.",
    en: "Successfully sent the email.",
  },
  CDMS_STUDY_SAFETY_INTG_USER_INVITATION_MAIL_SEND_FAIL: {
    ko: "메일 전송에 실패하였습니다. ({message})",
    en: "Failed to send the email. {{message}}",
  },
  CDMS_STUDY_SAFETY_INTG_USER_VIEW_TITLE: {
    ko: "대표 PV 지정",
    en: "Designate a Rep PV",
  },
  CDMS_STUDY_SAFETY_INTG_USER_VIEW_DESC: {
    ko: "해당 계정의 E-mail로 대표 PV 지정 승인 요청 메일을 보냅니다.",
    en: "Send a Rep PV designation approval mail to the email address.",
  },
  CDMS_STUDY_SAFETY_INTG_USER_ORGANIZATION_MODIFY_SUCC: {
    ko: "Safety Organization이 변경되었습니다.",
    en: "The Safety Organization has been changed.",
  },
  CDMS_STUDY_SAFETY_INTG_USER_ORGANIZATION_MODIFY_FAIL: {
    ko: "Safety Organization 변경에 실패하였습니다. ({message})",
    en: "Failed to change the Safety Organization.",
  },
  CDMS_STUDY_SAFETY_INTG_REQUEST_SUCC: {
    ko: "Safety Integration 요청에 성공하였습니다.",
    en: "Succeeded to Safety Integration request.",
  },
  CDMS_STUDY_SAFETY_INTG_REQUEST_FAIL: {
    ko: "Safety Integration 요청에 실패하였습니다. ({message})",
    en: "Failed to Safety Integration request. ({message})",
  },
};

MvnCodeInitiate(CDMS_STUDY_SAFETY_INTEGRATION_LANG);

export const CDMS_STUDY_SAFETY_INTEGRATION_MAIL_LANG: {
  CDMS_STUDY_SAFETY_INTEGRATION_MAIL_REP_PV_INVITATION_TITLE: MvnCodeFE;
  CDMS_STUDY_SAFETY_INTEGRATION_MAIL_REP_PV_INVITATION_CONTENT: MvnCodeFE;
  CDMS_STUDY_SAFETY_INTEGRATION_MAIL_REP_PV_INVITATION_EXPIRY_DATE: MvnCodeFE;
  CDMS_STUDY_SAFETY_INTEGRATION_MAIL_LINK_BUTTON: MvnCodeFE;
  CDMS_STUDY_SAFETY_INTEGRATION_MAIL_REP_PV_INVITATION_ACCEPT_TITLE: MvnCodeFE;
  CDMS_STUDY_SAFETY_INTEGRATION_MAIL_REP_PV_INVITATION_ACCEPT_CONTENT: MvnCodeFE;
  CDMS_STUDY_SAFETY_INTEGRATION_MAIL_REP_PV_INVITATION_REJECT_TITLE: MvnCodeFE;
  CDMS_STUDY_SAFETY_INTEGRATION_MAIL_REP_PV_REMOVED_TITLE: MvnCodeFE;
  CDMS_STUDY_SAFETY_INTEGRATION_MAIL_REP_PV_REMOVED_CONTENT: MvnCodeFE;
  CDMS_STUDY_SAFETY_INTEGRATION_MAIL_REP_PV_CHANGED_TITLE: MvnCodeFE;
  CDMS_STUDY_SAFETY_INTEGRATION_MAIL_ORG_CHANGED_TITLE: MvnCodeFE;
} = {
  CDMS_STUDY_SAFETY_INTEGRATION_MAIL_REP_PV_INVITATION_TITLE: {
    ko: "대표 PV 지정에 대한 승인 요청드립니다.",
    en: "Please approve your Rep PV designation.",
  },
  CDMS_STUDY_SAFETY_INTEGRATION_MAIL_REP_PV_INVITATION_CONTENT: {
    ko: "아래 버튼을 눌러 Maven CDMS로 이동하여 대표 PV 지정에 대한 승인을 진행해 주세요.\n승인 후 소속된 Safety Organization 중 하나의 Organization을 선택하시면 해당 Safety Organization으로 Maven CDMS의 이상반응 데이터가 연동됩니다.",
    en: "Please click the button below to proceed to Maven CDMS and approve your representative PV designation.\nOnce approved, select a Safety Organization which to link the adverse event data from Maven CDMS.",
  },
  CDMS_STUDY_SAFETY_INTEGRATION_MAIL_REP_PV_INVITATION_EXPIRY_DATE: {
    ko: "해당 메일의 유효기간은 다음과 같습니다.\n{expiryDate}",
    en: "The expiration period of this mail is as follows.\n{expiryDate}",
  },
  CDMS_STUDY_SAFETY_INTEGRATION_MAIL_LINK_BUTTON: {
    ko: "Maven CDMS로 이동",
    en: "Move to Maven CDMS",
  },
  CDMS_STUDY_SAFETY_INTEGRATION_MAIL_REP_PV_INVITATION_ACCEPT_TITLE: {
    ko: "대표 PV 승인 및 Organization 선택이 완료되었습니다.",
    en: "Rep PV approval and organization selection has been completed.",
  },
  CDMS_STUDY_SAFETY_INTEGRATION_MAIL_REP_PV_INVITATION_ACCEPT_CONTENT: {
    ko: "해당 스터디의 대표 PV로 지정되었습니다.\n선택하신 Safety Organization으로 Maven CDMS의 이상반응 데이터가 연동됩니다.\n연동 받을 Safety Organization 변경은 Maven CDMS에서 가능합니다.",
    en: "You have been designated as the Rep PV for this study.\nAdverse event data from Maven CDMS will be linked to the selected Safety Organization.\nYou can change the linked Safety Organization in Maven CDMS, if needed.",
  },
  CDMS_STUDY_SAFETY_INTEGRATION_MAIL_REP_PV_INVITATION_REJECT_TITLE: {
    ko: "대표 PV 지정이 거절되었습니다.",
    en: "You have rejected the Rep PV designation.",
  },
  CDMS_STUDY_SAFETY_INTEGRATION_MAIL_REP_PV_REMOVED_TITLE: {
    ko: "대표 PV가 해제되었습니다.",
    en: "The Rep PV has been unassigned.",
  },
  CDMS_STUDY_SAFETY_INTEGRATION_MAIL_REP_PV_REMOVED_CONTENT: {
    ko: "대표 PV 지정이 해제되어 Safety 연동 Organization 정보가 삭제되었습니다.\nSafety 연동을 계속하시려면 새로운 대표 PV 지정 및 Safety Organization 선택을 완료해주세요.",
    en: "The organization information has been deleted because the rep PV has been unassigned. To continue with the Safety Integration, please select a new rep PV and Safety Organization.",
  },
  CDMS_STUDY_SAFETY_INTEGRATION_MAIL_REP_PV_CHANGED_TITLE: {
    ko: "대표 PV가 변경되었습니다.",
    en: "The Rep PV has been changed.",
  },
  CDMS_STUDY_SAFETY_INTEGRATION_MAIL_ORG_CHANGED_TITLE: {
    ko: "Safety Integration Organization이 변경되었습니다.",
    en: "The Safety Integration Organization has been changed.",
  },
};

MvnCodeInitiate(CDMS_STUDY_SAFETY_INTEGRATION_MAIL_LANG);

export const CDMS_STUDY_SAFETY_INTEGRATION_AUDIT_LANG: {
  CDMS_STUDY_SAFETY_INTG_AUDIT_REP_PV_UNASSIGNED: MvnCodeFE;
  CDMS_STUDY_SAFETY_INTG_AUDIT_REP_PV_APPROVED: MvnCodeFE;
  CDMS_STUDY_SAFETY_INTG_AUDIT_REP_PV_APPROVE_REJECTED: MvnCodeFE;
  CDMS_STUDY_SAFETY_INTG_AUDIT_APPROVAL_MAIL_SENT: MvnCodeFE;
  CDMS_STUDY_SAFETY_INTG_AUDIT_REASON_PV_USER_CHANGED: MvnCodeFE;
  CDMS_STUDY_SAFETY_INTG_AUDIT_REASON_STUDY_USER_REMOVED: MvnCodeFE;
  CDMS_STUDY_SAFETY_INTG_AUDIT_REASON_STUDY_USER_EXPIRED: MvnCodeFE;
  CDMS_STUDY_SAFETY_INTG_AUDIT_REASON_ROLE_CHANGED: MvnCodeFE;
  CDMS_STUDY_SAFETY_INTG_AUDIT_REASON_ROLE_REMOVED: MvnCodeFE;
  CDMS_STUDY_SAFETY_INTG_AUDIT_REASON_USER_REMOVED: MvnCodeFE;
  CDMS_STUDY_SAFETY_INTG_AUDIT_REASON_USER_EXPIRED: MvnCodeFE;
  CDMS_STUDY_SAFETY_INTG_AUDIT_REASON_PV_PRIVILEGE_REMOVED: MvnCodeFE;
} = {
  CDMS_STUDY_SAFETY_INTG_AUDIT_REP_PV_UNASSIGNED: {
    ko: "대표 PV 해제 ({reason})",
    en: "Unassign rep PV ({reason})",
  },
  CDMS_STUDY_SAFETY_INTG_AUDIT_REP_PV_APPROVED: {
    ko: "대표 PV 승인 및 연동 Org 선택 완료",
    en: "Complete rep PV approval and organization selection",
  },
  CDMS_STUDY_SAFETY_INTG_AUDIT_REP_PV_APPROVE_REJECTED: {
    ko: "대표 PV 승인 거절",
    en: "Reject rep PV approval",
  },
  CDMS_STUDY_SAFETY_INTG_AUDIT_APPROVAL_MAIL_SENT: {
    ko: "{recipient}에게 메일 전송",
    en: "Send mail to {recipient}",
  },
  CDMS_STUDY_SAFETY_INTG_AUDIT_REASON_PV_USER_CHANGED: {
    ko: "대표 PV 변경",
    en: "Change rep PV",
  },
  CDMS_STUDY_SAFETY_INTG_AUDIT_REASON_STUDY_USER_REMOVED: {
    ko: "Study User 제거",
    en: "Study user deleted",
  },
  CDMS_STUDY_SAFETY_INTG_AUDIT_REASON_STUDY_USER_EXPIRED: {
    ko: "Study User 만료",
    en: "Study user expired",
  },
  CDMS_STUDY_SAFETY_INTG_AUDIT_REASON_ROLE_CHANGED: {
    ko: "Role 변경",
    en: "Role changed",
  },
  CDMS_STUDY_SAFETY_INTG_AUDIT_REASON_ROLE_REMOVED: {
    ko: "Role 제거",
    en: "Role deleted",
  },
  CDMS_STUDY_SAFETY_INTG_AUDIT_REASON_USER_REMOVED: {
    ko: "System User 제거",
    en: "System user deleted",
  },
  CDMS_STUDY_SAFETY_INTG_AUDIT_REASON_USER_EXPIRED: {
    ko: "System User 만료",
    en: "System user expired",
  },
  CDMS_STUDY_SAFETY_INTG_AUDIT_REASON_PV_PRIVILEGE_REMOVED: {
    ko: "Privilege 제거",
    en: "Privilege deleted",
  },
};

MvnCodeInitiate(CDMS_STUDY_SAFETY_INTEGRATION_AUDIT_LANG);
